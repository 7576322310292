.ais-SearchBox { margin: 1em 0; }
.ais-SearchBox-input {
    font-size: 20px;
    font-family: 'Open Sans Condensed',sans-serif;
    margin-right: 3px;
    width: 50vw;
}

.ais-SearchBox-submit {
    margin-right: 3px;
}

.ais-SearchBox-form {
    display: flex;
    justify-content: center;
}

.ais-Hits-list {
    list-style-type: none;
}

.ais-Hits-item {
    margin-bottom: 1em;
    width: 50vw;
}
.ais-Pagination {
    margin-top: 1em ;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.ais-Pagination-list {
    margin-top: 1em;
    list-style-type: none;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50vw;
}

.left-panel { float: left; width: 250px; }
.right-panel { margin-left: 260px; }
.ais-InstantSearch { max-width: 960px; overflow: hidden; margin: 0 auto }
.ais-Hits-item img { margin-right: 1em }
.hit-name { margin-bottom: .5em }
.hit-description { color: #888; font-size: 14px; margin-bottom: .5em }